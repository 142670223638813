import React, { useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Footer from '../Comman/Footer';
import Sidebar from '../Comman/Sidebar';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import axios from 'axios';

export default function Dashboard() {  // The component name should start with uppercase 'D'
    const [dashboardData, setDashboardData] = useState(null);

    const navigate = useNavigate();  // Initialize navigate

    // Function to handle deposit button click
    const handleDepositClick = () => {
        navigate('/deposit');  // Navigate to the deposit page
    };
    const handlLiquidityClick = () => {
        navigate('/liquidity');  // Navigate to the deposit page
    };

    const getDashboardData = async () => {
        try {
          await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
              method: "user",
              submethod: "admindashd",
              key: process.env.REACT_APP_KEY,
            })
            .then((res) => {
              console.log("res.data:",res.data)
              setDashboardData(res.data.data);
              if (res.data.error) {
                return "";
              }
            });
        } catch (err) {
          console.log("error : ", err);
        }
      };
    
      console.log("dashboardData:", dashboardData)
    
      useEffect(() => {
        getDashboardData();
        
      }, []);

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start */}
                    <Sidebar />
                    {/* Page sidebar end */}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Welcome to BotVerse !</h3>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-end">
                                        {/* Deposit Button */}
                                        <button className="btn btn-primary" onClick={handleDepositClick}>
                                            Deposit
                                        </button>
                                <div className="col-sm-4">
                                    <div className="d-flex justify-content-end">
                                        {/* Deposit Button */}
                                        <button className="btn btn-primary" onClick={handlLiquidityClick}>
                                            Add Liquidity
                                        </button>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Total Sale Card */}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total User</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                             {dashboardData ? dashboardData.t_user : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Bot Purchase</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                             {dashboardData ? dashboardData.active_user : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Bot Purchase</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.t_b_amount : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Bot Liquidity</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                             ${dashboardData ? dashboardData.t_staking_s : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Liquidity</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                             ${dashboardData ? dashboardData.balance : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Roi</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.income_roi : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Sponser Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.sponser_income : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Pending Sponser Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.pending_s_reward : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Mobilisation Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.mobilisation_inc : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Activity Club Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.activity_club_inc : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.t_withdrawal : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Pending Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                            $ {dashboardData ? dashboardData.t_pending_withdrwal : "0"}

                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">                                                    
                                                    <div className="order-chart">
                                                        <div className="chart" id="sale-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* You can uncomment and add more cards here */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
