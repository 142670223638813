import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';

export default function LiquidityHistorySidbar() {
    const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "staking",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                search,
                page: currentPage,
                pageSize: pageSize,
            });
            
            setLoading(false);
    
            if (res.data.resCode !== 200) {
                console.error("Error: ", res.data.error); // Log the error from the backend
                setData([]); // Clear data if error occurs
                return;
            }
    
            setData(res.data.data); // Set the actual data
            setPages(Math.ceil(res.data.dataLength / pageSize)); // Pagination logic
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };
    
    // console.log('data', data)
    useEffect(() => {
        if (search || fromDate || toDate) {
            setCurrentPage(1); // Reset to page 1 when searching or filtering
        }
        getData();
    }, [currentPage, search, fromDate, toDate, pageSize]); // Updated dependencies

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Liquidity History</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header"></div>
                                        <div className="table-responsive signal-table p-3">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>                                                        
                                                        <th>Address</th>                                                        
                                                        <th>Hash</th>                                                        
                                                        <th>Amount</th>                                                        
                                                        <th>Type</th>                                                        
                                                        <th>Token</th>                                                        
                                                        <th>Rate</th>                                                        
                                                        <th>Datetime</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                    {data && data?.length
                                        ? data.map((e, i) => {
                                            let ind = currentPage * pageSize - pageSize + (i + 1);
                                            return (
                                                <tr>
                                                    <td >
                                                        {ind}
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            className="text-primary"
                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                                            rel="noreferrer"
                                                        >
                                                            {formatAddress(e.hash)}
                                                        </a>
                                                        {e.hash ? <Copy data={e.hash} /> : ""}
                                                    </td>
                                                    <td>
                                                        <a
                                                            target="_blank"
                                                            className="text-primary"
                                                            href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                                            rel="noreferrer"
                                                        >
                                                            {formatAddress(e.address)}
                                                        </a>
                                                        {e.address ? <Copy data={e.address} /> : ""}
                                                    </td>
                                                    <td >
                                                    <span>${e.amount ? e.amount : "0"}</span>                                                   
                                                     </td>
                                                     <td>
                                                     <span>{e.token_type ? e.token_type : "0"}</span> 
                                                     </td>
                                                    <td>
                                                         <span>{e.token ?(e.token).toFixed(5) : "0"}</span>
                                                    </td>
                                                    <td>
                                                        <span>${e.rate ? e.rate : "0"}</span>
                                                    </td>
                                                    <td >
                                                    <span>{e.createdAt ? e.createdAt : "0"}</span>
                                                    </td>
                                                    {/* <td>
                                                        {e.status === true ? (
                                                            <div className="text-success">
                                                                Active
                                                            </div>
                                                        ) : (
                                                            <div className="text-danger`">
                                                                DeActive
                                                            </div>
                                                        )}
                                                    </td> */}
                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-flex justify-content-center py-4'>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
