import React, { useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import axios from 'axios';

export default function Deposit() {
    // State variables to hold form inputs
    const [address, setAddress] = useState('');
    const [ref_address, setref_Address] = useState('');
    const [amount, setAmount] = useState(0);
    const [days, setDays] = useState('');
    const [rewardPer, setRewardPer] = useState('');
    const [loading, setLoading] = useState(false);  // Loading state for the form submission
    const [error, seterror] = useState('');
    const [trdone, settrdone] = useState(false);
    const [lvlincome, setlvlincome] = useState(true);



    const onStake = async () => {
        if (!address) {
            seterror("Enter Address")
            return
        }
        if (!ref_address) {
            seterror("Enter Ref Address")
            return
        }
        if (!amount) {
            seterror("Enter Amount")
            return
        }
        settrdone(true)
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transaction",
                submethod: "insertadmin",
                address: address,
                ref_address: ref_address,
                amount: amount,
                token: amount,
                l_inc:lvlincome,
                rate: 1,
                key: process.env.REACT_APP_KEY,

            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                setAmount(0);
                setref_Address('')
                setAddress('')
                settrdone(false)
                if (res.data.error) {
                    seterror(res.data.error)

                    return "";
                }
                seterror(res.data.data)

            });
    };
    useEffect(() => {
        console.log("lvlincome", lvlincome);

    }, [lvlincome])
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="login-card login-dark">
                                        <div className="login-main">
                                            <div className="theme-form" >
                                                <h2 className="text-center">Deposit</h2>
                                                <div className="form-group">
                                                    <label className="col-form-label">Address</label>
                                                    <input
                                                        className="form-control"
                                                        required
                                                        placeholder="Address"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Ref.Address</label>
                                                    <div className="form-input position-relative">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="Ref.Address"
                                                            value={ref_address}
                                                            onChange={(e) => setref_Address(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">Amount ($25,$100,$500)</label>
                                                    <div className="form-input position-relative">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            placeholder="Amount"
                                                            value={amount}
                                                            onChange={(e) => setAmount(Number(e.target.value))}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" name="lvlv" id="leveincome" checked={lvlincome} onChange={(e) => setlvlincome(e.target.checked)} />
                                                    <label class="form-check-label" for="leveincome">Level Income</label>
                                                </div>

                                                <div className='text-warning'>
                                                    {error}
                                                </div>

                                                <div className="form-group mb-0 checkbox-checked mb-5">
                                                    <div className="text-end mt-3">
                                                        <button type="submit" className="btn btn-primary btn-block w-100 text-white" disabled={trdone} onClick={() => onStake()}>
                                                            {trdone ? 'Submitting...' : 'Submit'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
}
