import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';
import Web3 from "web3";

export default function PendingWithdrawal() {
    const { formatAddress, settings, web3, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authNumber, setAuthNumber] = useState('');

    const [account, setAccount] = useState(null);
    const [web3c, setWeb3c] = useState(null);
    const [value, setValue] = useState(0);
    const [trdone, settrdone] = useState(true); // Only one declaration here
    const [selected_address, setselected_address] = useState([]);
    const [selected_address_amt, setselected_address_amt] = useState([]);
    const [selected_address_amt_as, setselected_address_amt_as] = useState([]);
    const [msg, setMsg] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);


    const insertCode = async () => {
        try {
            const state = JSON.parse(localStorage.getItem('mark#0143'));
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "verify",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                authNumber,
                username: state.username,
                _id: state._id,
            });

            const { resCode, error, token } = response.data;

            if (resCode === 200 && token) {
                setMsg("Verified successfully");
                setShowMsg(true);
                setIsAuthenticated(true);
            } else {
                setErrorMessage(error || "Verification failed. Please try again.");
                setError(true);
            }
        } catch (err) {
            console.error("Error inserting data:", err);
            setErrorMessage("Network error. Please try again later.");
            setError(true);
        }
    };

    const connectMetaMask = async () => {
        if (window.ethereum) {
            // MetaMask exists
            try {
                // Request account access if needed
                const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
                const web3Instance = new Web3(window.ethereum);

                // Set the web3 instance and the user's account
                setWeb3c(web3Instance);
                setAccount(accounts[0]);

                console.log("Connected account:", accounts[0]);
            } catch (error) {
                console.error("User denied account access or error occurred:", error);
            }
        } else {
            // MetaMask is not installed
            alert("Please install MetaMask to use this app.");
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "withdrawal",
                    submethod: "getpw",
                    key: process.env.REACT_APP_KEY,
                    search: search,
                    type: 1,
                    fromdate: fromdate,
                    todate: todate,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    console.log("user data", res.data.data);
                    setLoading(false);
                    if (res.data.error) {
                        setdata(res.data.data);
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };

    const selectAll = (event) => {
        if (event?.target?.checked) {
            // Reset arrays and total amount to start fresh
            let allSelectedAddresses = [];
            let allSelectedAmountsWei = [];
            let allSelectedAmounts = [];
            let totalAmount = 0;

            // Loop through all data to check each item and accumulate values
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = true;  // Check the checkbox

                const address = item.address;
                const amount = parseFloat(item.amount);  // Assuming 'amount' is the right field for your use case
                const token = parseFloat(item.t_rcv);  // Assuming 'amount' is the right field for your use case
                // const token = web3?.utils?.toWei(item.token.toString(), "ether");  // Assuming 'amount' is the right field for your use case
                const amountWei = web3?.utils?.toWei(item.t_rcv.toString(), "ether");
                console.log("amountWei", amountWei);
                allSelectedAddresses.push(address);
                allSelectedAmountsWei.push(amountWei);
                allSelectedAmounts.push(amount);
                totalAmount += token;  // Accumulate total amount
            });

            // Update state with new selection
            setselected_address(allSelectedAddresses);
            setselected_address_amt(allSelectedAmountsWei);
            setselected_address_amt_as(allSelectedAmounts);
            setValue(totalAmount);  // Update the total value

        } else {
            // Uncheck all checkboxes and reset state
            data.forEach((item, index) => {
                const checkbox = document.getElementById(`chkbox${index}`);
                checkbox.checked = false;  // Uncheck the checkbox
            });

            // Clear state and reset total amount
            setselected_address([]);
            setselected_address_amt([]);
            setselected_address_amt_as([]);
            setValue(0);  // Reset total amount
        }
    };

    const withdrawMultiple = async () => {
        // console.log("account", account)
        settrdone(false)
        var contract = new web3c.eth.Contract(JSON.parse(settings.withdraw_abi), settings.withdraw_contract)


        // 1 - withdrawalProtocol
        // 0 - withdrawal
        await contract.methods.Withdrawal(selected_address, selected_address_amt, web3.utils.toWei(value.toString(), "ether")).send({ from: account },
            async function (err, transactionId) {
                console.log("tra", transactionId);
                if (err) {
                    settrdone(true)
                }
            }).then(
                async res => {
                    console.log("Ress", res);

                    await axios
                        .post(process.env.REACT_APP_BACKEND_LINK, {
                            method: "withdrawal",
                            submethod: "newsinsert",
                            key: process.env.REACT_APP_KEY,
                            hash: res.transactionHash,
                            // hash: "hash-1",
                            address: selected_address,
                            amount: selected_address_amt_as,
                            total: value,
                            type: 1,
                        })
                        .then((res) => {
                            for (let i = 0; i < selected_address.length; i++) {
                                const checkbox = document.getElementById(`chkbox${i}`);
                                if (checkbox) {
                                    checkbox.checked = false;
                                }

                            }
                            getData()
                            setselected_address([])
                            setselected_address_amt([])
                            setValue(0)
                            if (res.data.error) {
                                return "";
                            }
                            toastSuccess("Transaction Done")

                        });
                });
        settrdone(true)
    }
    const handleChange = (event, address, amount, token, receive) => {
        if (event?.target?.checked) {
            setselected_address([...selected_address, address]);
            // var amt = web3?.utils?.toWei(amount, "ether")
            console.log("DA", token, receive);

            const amt = web3.utils.toWei(receive, "ether");  // Assuming 'amount' is the right field for your use case
            // const amt = parseFloat(receive);
            console.log("DA", amt);

            setselected_address_amt([...selected_address_amt, amt]);
            setselected_address_amt_as([...selected_address_amt_as, amount]);
            setValue((prevValue) => prevValue + receive);

        } else {
            const index = selected_address.findIndex(item => item === address);
            if (index !== -1) {
                setselected_address(selected_address.filter((_, ind) => ind !== index));
                setselected_address_amt(selected_address_amt.filter((_, ind) => ind !== index));
                setselected_address_amt_as(selected_address_amt_as.filter((_, ind) => ind !== index));
                setValue((prevValue) => prevValue - receive);

            }
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);

    useEffect(() => {
        // console.log(selected_address);
        // console.log(selected_address_amt);
        // console.log(selected_address_amt_as);
    }, [selected_address, selected_address_amt])
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            {!isAuthenticated ? (
                                // Authentication Form
                                <div className="auth-form">
                                    <div className="form-group">
                                        <label className="form-label center">Authentication Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setAuthNumber(e.target.value)}
                                        />
                                    </div>
                                    {error && errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    {showMsg && msg && (
                                        <div className="alert alert-success" role="alert">
                                            {msg}
                                        </div>
                                    )}
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-block"
                                            onClick={insertCode}
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="row page-title">
                                        <div className="col-sm-6">
                                            <h3>Pending Withdrawal</h3>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="position-relative">
                                                <input type="text" value={search} onChange={handleSearchChange} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                                <span className="mdi mdi-magnify search-widget-icon" />
                                                <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex pe-5">
                                        <div>
                                            <h5 className="py-3 px-5 text-break">🟢{account ? formatAddress(account) : "Not Connected"}</h5>
                                            <h5 className="px-5">Total: {value} {process.env.REACT_APP_TICKER}</h5>
                                            <p className="px-5">
                                                <input className="p-5" type="checkbox" name="chkbox" id="chkbox" onChange={(ed) => selectAll(ed)} /> Select All
                                            </p>
                                        </div>
                                        <div>
                                            {account ? (
                                                trdone ? (
                                                    <button className="btn btn-success m-1" onClick={withdrawMultiple}>Give Withdrawal</button>
                                                ) : (
                                                    <img src="/assets/images/loader.gif" style={{ height: "80px" }} alt="icon" />
                                                )
                                            ) : (
                                                <button className="btn btn-success m-1" onClick={connectMetaMask}>Connect Metamask</button>
                                            )}
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-header"></div>
                                                    <div className="table-responsive signal-table p-3">
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Address</th>
                                                                    <th>Amount</th>
                                                                    <th>Fee(5%)</th>
                                                                    <th>Receive</th>
                                                                    <th>Bot</th>
                                                                    <th>DateTime</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data && data.length ? (
                                                                    data.map((e, i) => {
                                                                        let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{ind} <input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.amount, e.token, e.t_rcv)} /></td>
                                                                                <td>
                                                                                    <a target="_blank" className="text-primary" href={`${process.env.REACT_APP_EXPLORER}/tx/${e.address}`} rel="noreferrer">
                                                                                        {formatAddress(e.address)}
                                                                                    </a>
                                                                                    {e.address && <Copy data={e.address} />}
                                                                                </td>
                                                                                <td><span>${e.amount || "0"}</span></td>
                                                                                <td><span>{e.fee}</span></td>
                                                                                <td><span>${e.t_rcv}</span></td>
                                                                                <td>
                                                                                    <span>
                                                                                        {e.amount === 25 ? "BEGINNER BOT" : e.amount === 100 ? "INTERMEDIATE BOT" : e.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"}
                                                                                    </span>
                                                                                </td>
                                                                                <td>{e.datetime}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="6" className="text-center">No data found.</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        {loading && <div className="text-center"><p>Loading...</p></div>}
                                                    </div>
                                                    <div className="d-flex justify-content-center py-4">
                                                        <Pagination
                                                            currentPage={currentPage}
                                                            setCurrentPage={setCurrentPage}
                                                            pageSize={pageSize}
                                                            setPageSize={setPageSize}
                                                            pages={pages}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        </>
    )
}