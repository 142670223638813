import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Sidebar from '../Comman/Sidebar'
import Footer from '../Comman/Footer'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';
import Web3 from "web3";

export default function WithdrawalSidbar() {
    const { formatAddress, settings, web3, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
    const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
    const [bonusInc, setBonusInc] = useState(false);
    const [bonusPer, setBonusPer] = useState(0);
    const [bonusAmt, setBonusAmt] = useState(0);
    const [userwithdrawl, setuserwithdrawl] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [authNumber, setAuthNumber] = useState('');

    const [account, setAccount] = useState(null);
    const [web3c, setWeb3c] = useState(null);
    const [value, setValue] = useState(0);
    const [trdone, settrdone] = useState(true); // Only one declaration here
    const [selected_address, setselected_address] = useState([]);
    const [selected_address_amt, setselected_address_amt] = useState([]);
    const [selected_address_amt_as, setselected_address_amt_as] = useState([]);
    const [msg, setMsg] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);

    const getData = async () => {
        try {
            setLoading(true);
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "withdrawal",
                    submethod: "get",
                    key: process.env.REACT_APP_KEY,
                    search: search,
                    type: 1,
                    fromdate: fromdate,
                    todate: todate,
                    page: currentPage,
                    pageSize: pageSize,
                })
                .then((res) => {
                    console.log("user data", res.data.data);
                    setLoading(false);
                    if (res.data.error) {
                        setdata(res.data.data);
                        return "";
                    }
                    setdata(res.data.data);
                    setPages(Math.ceil(res.data.dataLength / pageSize));
                });
        } catch (err) {
            console.log("error : ", err);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        if (fromdate && todate) {
            setCurrentPage(1);
        }
        getData();
    }, [pages, pageSize, currentPage, search]);

    useEffect(() => {
        // console.log(selected_address);
        // console.log(selected_address_amt);
        // console.log(selected_address_amt_as);
    }, [selected_address, selected_address_amt])
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Withdrawal</h3>
                                </div>
                                {/* <div className="col-sm-4">
                                    <div className="position-relative">
                                        <input type="text" value={search} onChange={handleSearchChange} className="form-control" placeholder="Search..." autoComplete="off" id="search-options" />
                                        <span className="mdi mdi-magnify search-widget-icon" />
                                        <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options" />
                                    </div>
                                </div> */}
                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-header"></div>
                                            <div className="table-responsive signal-table p-3">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Address</th>
                                                            <th>Amount</th>
                                                            <th>Fee(5%)</th>
                                                            <th>Receive</th>
                                                            <th>Bot</th>
                                                            <th>DateTime</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data.length ? (
                                                            data.map((e, i) => {
                                                                let ind = currentPage * pageSize - pageSize + (i + 1);
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{ind}</td>
                                                                        <td>
                                                                            <a target="_blank" className="text-primary" href={`${process.env.REACT_APP_EXPLORER}/tx/${e.address}`} rel="noreferrer">
                                                                                {formatAddress(e.address)}
                                                                            </a>
                                                                            {e.address && <Copy data={e.address} />}
                                                                        </td>
                                                                        <td><span>${e.amount || "0"}</span></td>
                                                                        <td><span>{e.fee}</span></td>
                                                                        <td><span>${e.t_rcv}</span></td>
                                                                        <td>
                                                                            <span>
                                                                                {e.amount === 25 ? "BEGINNER BOT" : e.amount === 100 ? "INTERMEDIATE BOT" : e.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"}
                                                                            </span>
                                                                        </td>
                                                                        <td>{e.createdAt}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">No data found.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                {loading && <div className="text-center"><p>Loading...</p></div>}
                                            </div>
                                            <div className="d-flex justify-content-center py-4">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    pages={pages}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </main>
        </>
    )
}