import React, { useContext, useEffect, useState } from 'react';
import Header from '../Comman/Header';
import Sidebar from '../Comman/Sidebar';
import Footer from '../Comman/Footer';
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import Copy from "../Comman/Copy";
import { Link } from 'react-router-dom';
import Pagination from '../Comman/Pagination';

export default function Users() {
    const { formatAddress, toastSuccess, toastError } = useContext(AdminContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [editAddress, setEditAddress] = useState("");
    const [userwithdraw, setuserwithdraw] = useState(false);
    const [userstaking, setuserstaking] = useState(false);
    const [userLevel, setuserLevel] = useState(false);
    const [withdrawL, setwithdrawL] = useState(false);
    const [p_error, setp_error] = useState(null);

    const saveBonusIncome = async () => {
        try {
            if (!editAddress) {
                setp_error("Address not found.");
                return
            }

            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "user",
                    submethod: "updatew",
                    withdraw: userwithdraw,
                    staking: userstaking,
                    address: editAddress,
                    all_level_open: userLevel,
                    withdraw_l: withdrawL,
                    key: process.env.REACT_APP_KEY,
                })
                .then((res) => {
                    if (res.data.error) {
                        setp_error(res.data.error);
                    } else {
                        setEditAddress("");
                        setp_error("");
                    }
                    getData()
                });

        } catch (err) {
            console.log("error : ", err);
            setp_error(err.message);
        }
    };



    const editPopup = (address, withdraw, staking, all_level_open) => {
        setEditAddress(address);
        setuserwithdraw(withdraw);
        setuserstaking(staking);
        setuserLevel(all_level_open);

    }

    const getData = async () => {
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                search,
                fromdate: fromDate,
                todate: toDate,
                page: currentPage,
                pageSize,
            });

            // console.log("user data", res.data.data);
            setLoading(false);

            if (res.data.error) {
                toastError("Failed to fetch data"); // Improved error handling
                setData([]);
                return;
            }

            setData(res.data.data);
            setPages(Math.ceil(res.data.dataLength / pageSize));
        } catch (err) {
            console.log("error: ", err);
            toastError("An error occurred while fetching data"); // Improved error handling
            setLoading(false);
        }
    };

    useEffect(() => {
        if (search || fromDate || toDate) {
            setCurrentPage(1); // Reset to page 1 when searching or filtering
        }
        getData();
    }, [currentPage, search, fromDate, toDate, pageSize]); // Updated dependencies

    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    <Sidebar />
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>Users</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header"></div>
                                        <div className="table-responsive signal-table p-3">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>User Name</th>
                                                        <th>Ref. User Name</th>
                                                        <th>BOT</th>
                                                        <th>Liquidity</th>
                                                        <th>Total Team</th>
                                                        <th>Directs</th>
                                                        <th>Club</th>
                                                        <th>Salary</th>
                                                        <th>Withdrawal</th>
                                                        <th>Date</th>
                                                        <th>Report</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.length > 0 ? (
                                                        data.map((ele, i) => {
                                                            const index = currentPage * pageSize - pageSize + (i + 1);
                                                            return (
                                                                <tr key={ele.address}> {/* Added key prop */}
                                                                    <td>



                                                                        <span className="d-flex justify-content-center">
                                                                            {index}
                                                                            {ele.withdrawal_status ? <span class="badge bg-success">Withdraw</span> :
                                                                                <span class="badge bg-danger">Withdraw</span>
                                                                            }
                                                                            {ele.staking_income_status ? <span class="badge bg-success">ROI</span> :
                                                                                <span class="badge bg-danger">ROI </span>
                                                                            }
                                                                            &nbsp;
                                                                            <button type="button" className="btn btn-primary btn-xs" data-bs-toggle="modal" data-bs-target="#exampleModalgrid" onClick={() => editPopup(ele.address, ele.withdrawal_status, ele.staking_income_status, ele.all_level_open, ele.withdrawL)}>
                                                                                Edit
                                                                            </button>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.address)}
                                                                        </a>
                                                                        {ele.address && <Copy data={ele.address} />}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/tx/${ele.ref_address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(ele.ref_address)}
                                                                        </a>
                                                                        {ele.ref_address && <Copy data={ele.ref_address} />}
                                                                    </td>
                                                                    <td>
                                                                        <span>${ele.t_staking ? ele.t_staking : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>${ele.t_staking_s ? ele.t_staking_s : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.level_users.length ? ele.level_users.length : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.level_users.length ? ele.level_users.length : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.club ? ele.club : "-"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.salary_inc ? ele.salary_inc : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.t_withdrawal ? ele.t_withdrawal : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{ele.createdAt ? ele.createdAt : "0"}</span>
                                                                    </td>
                                                                    {/* <td>
                                                                    <span>{ele.d_team ? ele.d_team : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                    <span>{ele.club ? ele.club : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                    <span>${ele.salary_inc ? ele.salary_inc : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                    <span>${ele.t_withdrawal ? ele.t_withdrawal : "0"}</span>
                                                                    </td>
                                                                    <td>{ele.createdAt}</td> */}
                                                                    <td>
                                                                        <span className="me-3">
                                                                            <Link
                                                                                className="edit-appointment"
                                                                                type="button"
                                                                                to={`/membersreport/${ele.address}`}
                                                                            >
                                                                                <i className="fa fa-book fs-18" aria-hidden="true" />
                                                                            </Link>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : loading ? (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                <p>Loading...</p>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="10" className="text-center">
                                                                <p>No data found.</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-flex justify-content-center py-4'>
                                            <Pagination
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                pageSize={pageSize}
                                                setPageSize={setPageSize}
                                                pages={pages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="live-preview">

                    <div className="modal fade" id="exampleModalgrid" tabIndex={-1} aria-labelledby="exampleModalgridLabel">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalgridLabel">Grid Modals
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body">
                                    <form action="javascript:void(0);">
                                        <div className="row g-3">
                                            <div className="col-xxl-12">
                                                <div>
                                                    <label htmlFor="firstName" className="form-label">Address</label>
                                                    <input type="text" className="form-control" id="firstName" value={editAddress} />
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <label className="form-label">Status</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="with" id="withst" checked={userwithdraw} onChange={(e) => setuserwithdraw(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="withst">Withdraw Status</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="sta" id="staki" checked={userstaking} onChange={(e) => setuserstaking(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="staki">Staking Income</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="sta" id="staki" checked={userLevel} onChange={(e) => setuserLevel(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="staki">Open All Level</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="sta" id="staki" checked={withdrawL} onChange={(e) => setwithdrawL(e.target.checked)} />
                                                        <label className="form-check-label" htmlFor="staki">Admin Withdraw</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => saveBonusIncome()}>Submit</button>
                                                </div>
                                            </div>
                                            {/*end col*/}
                                        </div>
                                        {/*end row*/}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
}
