import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AdminContext } from '../../AdminContext';

export default function Sidebar() {
    const { sidebar, setSidebar } = useContext(AdminContext);
    return (
        <>
            <div className="overlay" />
            <aside className={sidebar ? "page-sidebar d-lg-none d-sm-block d-block" : 'd-none'} data-sidebar-layout="stroke-svg">
                <div className="left-arrow" id="left-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-left" />
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul className="sidebar-menu" id="simple-bar">
                        <li className="pin-title sidebar-list p-0">
                            <h5 className="sidebar-main-title">Pinned</h5>
                        </li>
                        <li className="line pin-line" />
                        <li className="sidebar-main-title">General</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/dashboard" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Dashboard</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Users</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/purchasehistorysidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Purchase History</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/liquidityhistorysidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Liquidity History</span>
                            </Link>
                        </li>


                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/sponserrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Sponser Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/StakingRewardSidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Reward</span>
                            </Link>
                        </li>

                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/tradeunmdobilisationidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Trade Fund Mobilisation</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/salaryrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Salary Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/activityclubrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Activity Club Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pendingwithdrawalsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawalsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pendingsponsersidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Sponser Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/pendingstakingincome" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Staking Reward</span>
                            </Link>
                        </li>



                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li> */}
                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li> */}

                    </ul>
                </div>
                <div className="right-arrow" id="right-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-right" />
                    </svg>
                </div>
            </aside>
            <aside className="page-sidebar d-lg-block d-sm-none d-none" data-sidebar-layout="stroke-svg">
                <div className="left-arrow" id="left-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-left" />
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul className="sidebar-menu" id="simple-bar">
                        <li className="pin-title sidebar-list p-0">
                            <h5 className="sidebar-main-title">Pinned</h5>
                        </li>
                        <li className="line pin-line" />
                        <li className="sidebar-main-title">General</li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/dashboard" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Dashboard</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/users" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Users</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/purchasehistorysidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Purchase History</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/liquidityhistorysidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Liquidity History</span>
                            </Link>
                        </li>


                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/sponserrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Sponser Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/StakingRewardSidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking Reward</span>
                            </Link>
                        </li>

                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/tradeunmdobilisationidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Trade Fund Mobilisation</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/salaryrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Salary Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/activityclubrewardsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Activity Club Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pendingwithdrawalsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/withdrawalsidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Withdrawal</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/pendingsponsersidbar" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Sponser Reward</span>
                            </Link>
                        </li>
                        <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg>
                            <Link to="/pendingstakingincome" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Pending Staking Reward</span>
                            </Link>
                        </li>



                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li> */}
                        {/* <li className="line"> </li>
                        <li className="sidebar-main-title">Report</li> */}
                        {/* <li className="sidebar-list">
                            <svg className="pinned-icon">
                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Pin" />
                            </svg><Link to="/staking" className="sidebar-link">
                                <svg className="stroke-icon">
                                    <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Home" />
                                </svg><span>Staking</span>
                            </Link>
                        </li> */}

                    </ul>
                </div>
                <div className="right-arrow" id="right-arrow">
                    <svg className="feather">
                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-right" />
                    </svg>
                </div>
            </aside>
        </>
    )
}
