import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import  { AdminContext } from '../../AdminContext'
export default function Header() {
    const { token, pWithdrawal, setPWithdrawal, pwtoken, fromLocation, sidebar, setSidebar  } = useContext(AdminContext);
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const logOut = async () => {
        try {
            console.log("logout");
    
            // Retrieve user data from localStorage
            let userData = localStorage.getItem("user1f2s24dsaf7");
            
            // Check if userData exists
            if (!userData) {
                console.warn("No user data found in localStorage.");
                localStorage.clear();
                navigate("/");
                return;  // Exit the function since there's no user data to process
            }
    
            // Parse the user data
            let user = JSON.parse(userData);
    
            // Make the API call to logout
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "logout",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                username: user.username,
                _id: user.id,
            });
    
            const { resCode } = response.data;
            console.log('response.data', response.data);
    
            // If logout is successful, clear localStorage and navigate
            if (resCode === 200) {
                localStorage.clear();
                navigate("/");
            }
    
        } catch (err) {
            console.error("Error inserting data:", err);
        }
    };
    
    
    useEffect(() => {
        // if (location.pathname != "/pending-withdrawal") {
        if (location.pathname != fromLocation) {
            // setPWithdrawal(false);
            localStorage.removeItem("2faAuth");
        }
    }, []);

    return (
        <>
            <header className="page-header row">
                <div className="logo-wrapper d-flex align-items-center col-auto"><a href="index.html"><img className="for-light" style={{width:"160px"}} src="../assets/images/logo/logo.png" alt="logo" /><img className="for-dark" src="../assets/images/logo/dark-logo.png" alt="logo" /></a><a className="close-btn" href="javascript:void(0)">
                    <div className="toggle-sidebar"  onClick={() => setSidebar(!sidebar)}>
                        <div className="line" />
                        <div className="line" />
                        <div className="line" />
                    </div></a></div>
                <div className="page-main-header col">
                    <div className="header-left d-lg-block d-none">

                    </div>
                    <div className="nav-right">
                        <ul className="header-right">
                            <li className="profile-dropdown custom-dropdown" onClick={() => setShowMenu(!showMenu)}>
                                <div className="d-flex align-items-center">
                                    <img src="../assets/images/profile.png" alt="" />
                                    <div className="flex-grow-1">
                                        <h5></h5><span></span>
                                    </div>
                                </div>
                                <div className={showMenu ? "custom-menu overflow-hidden show" : "custom-menu overflow-hidden"}>
                                    <ul>
                                       
                                       
                                        <li className="d-flex">
                                            <svg className="svg-color">
                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#Login" />
                                            </svg><span className="ms-2" onClick={() => logOut()}>Log Out</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </header >

        </>
    )
}
