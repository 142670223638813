import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { GMCContext } from "../GMCContext";

function QrCodeAuth() {
  const [authNumber, setAuthNumber] = useState("");
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const insertCode = async () => {
    console.log("object");
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "verify",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        authNumber: authNumber,
        username: state.username,
        _id: state._id,
      });
      console.log("response", response);

      const { resCode, error, token } = response.data;
      if (resCode === 200) {
        console.log("response.resCode", resCode);
        if (token) {
          setMsg("Verified successfully");
          setShowMsg(true);
          localStorage.setItem("mark#321", token);
          localStorage.setItem(
            "mark#0143",
            JSON.stringify({ username: state.username, id: state._id })
          );
          navigate("/dashboard");
        } else {
          setErrorMessage("Unable to generate token.");
          setError(true);
        }
      } else {
        setErrorMessage(error || "Verification failed. Please try again.");
        setError(true);
      }
    } catch (err) {
      console.error("Error inserting data:", err);
      setErrorMessage("Network error. Please try again later.");
      setError(true);
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="login-card login-dark">
            <div className="login-main">
              <form className="theme-form">
                <div className="form-group">
                  <label className="form-label">Authentication Number</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setAuthNumber(e.target.value)}
                  />
                </div>

                {error && errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {showMsg && msg && (
                  <div className="alert alert-success" role="alert">
                    {msg}
                  </div>
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => insertCode()}
                  >
                    Log In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodeAuth;
