import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 footer-copyright">
                            <p className="mb-0">Copyright 2024 © Bot Verse.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
