import logo from './logo.svg';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Components/Login';
// import Staking from './Components/Report/Staking';
import Users from './Components/MembersReport/Users';
import Deposit from './Components/Dashboard/Deposit';
// import StakingReport from './Components/MembersReport/StakingReport';
import MemberReport from './Components/MembersReport/MemberReport';
import TeamReport from './Components/MembersReport/TeamReport';
import ActivityClubRewardReport from './Components/MembersReport/ActivityClubRewardReport';
import LiquidityHistoryReport from './Components/MembersReport/LiquidityHistoryReport';
import PurchaseHistoryReport from './Components/MembersReport/PurchaseHistoryReport';
import RoyaltyRewarReport from './Components/MembersReport/RoyaltyRewarReport';
import SalaryRewardReport from './Components/MembersReport/SalaryRewardReport';
import SponserReward from './Components/MembersReport/SponserReward';
// import StakingRewardReport from './Components/MembersReport/StakingRewardReport';
import TradeFundMobilisationReport from './Components/MembersReport/TradeFundMobilisationReport';
import Liquidity from './Components/Dashboard/Liquidity';
import PurchaseHistorySidbar from './Components/SidbarMenu/PurchaseHistorySidbar';
import LiquidityHistorySidbar from './Components/SidbarMenu/LiquidityHistorySidbar';
import SponserRewardSidbar from './Components/SidbarMenu/SponserRewardSidbar';
import TradeFundMobilisationSidbar from './Components/SidbarMenu/TradeFundMobilisationSidbar';
import SalaryRewardSidbar from './Components/SidbarMenu/SalaryReward';
import ActivityClubRewardSidbar from './Components/SidbarMenu/ActivityClubRewardSidbar';
import ProtecteRoutes from './Components/ProtecteRoutes';
import QrCodeAuth from './Components/QrCodeAuth';
import PendingWithdrawalSidbar from './Components/SidbarMenu/PendingWithdrawalSidbar';
import PendingSponserSidbar from './Components/SidbarMenu/PendingSponserSidbar';
import PendingStakingIncome from './Components/SidbarMenu/PendingStakingIncome';
import StakingRewardReport from './Components/MembersReport/StakingRewardReport';
import StakingRewardSidbar from './Components/SidbarMenu/StakingRewardSidbar';
import WithdrawalSidbar from './Components/SidbarMenu/WithdrawalSidbar';





// import RoiOfRoiReportSidbar from './Components/SidbarMenu/RoiOfRoiReportSidbar';
// import RoiOfRoiReport from './Components/MembersReport/RoiOfRoiReport';
// import RoyaltyRewardSidbar from './Components/SidbarMenu/RoyaltyRewardSidbar';
// import TeamReport from './Components/MembersReport/TeamReport';

function App() {
  return (
    <>
      {/* <Dashboard /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/authentication" element={<QrCodeAuth />} />
          <Route element={<ProtecteRoutes />} >

          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/staking" element={<Staking />} /> */}
          <Route path="/membersreport/:address" element={<MemberReport />} />
          {/* <Route path="/stakingreport" element={<StakingReport />} /> */}
          {/* <Route path="/teamreport" element={<TeamReport />} /> */}
          <Route path="/users" element={<Users />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/liquidity" element={<Liquidity />} />
          <Route path="/teamreport" element={<TeamReport />} />
          <Route path="/activityclubrewardreport" element={<ActivityClubRewardReport />} />
          <Route path="/liquidityhistoryreport" element={<LiquidityHistoryReport />} />
          <Route path="/purchasehistoryreport" element={<PurchaseHistoryReport />} />
          <Route path="/royaltyrewardreport" element={<RoyaltyRewarReport />} />
          <Route path="/salaryrewardreport" element={<SalaryRewardReport />} />
          <Route path="/sponserrewardreport" element={<SponserReward />} />
          {/* <Route path="/roiofroireport" element={<RoiOfRoiReport />} /> */}
          {/* <Route path="/stakingrewardreport" element={<StakingRewardReport />} /> */}
          <Route path="/tradefundmobilistionreport" element={<TradeFundMobilisationReport />} />
          <Route path="/purchasehistorysidbar" element={<PurchaseHistorySidbar />} />
          <Route path="/liquidityhistorysidbar" element={<LiquidityHistorySidbar />} />
          <Route path="/sponserrewardsidbar" element={<SponserRewardSidbar />} />
          <Route path="/tradeunmdobilisationidbar" element={<TradeFundMobilisationSidbar />} />
          <Route path="/salaryrewardsidbar" element={<SalaryRewardSidbar />} />
          <Route path="/activityclubrewardsidbar" element={<ActivityClubRewardSidbar />} />
          <Route path="/pendingwithdrawalsidbar" element={<PendingWithdrawalSidbar />} />
          <Route path="/pendingsponsersidbar" element={<PendingSponserSidbar />} />
          <Route path="/pendingstakingincome" element={<PendingStakingIncome />} />
          <Route path="/StakingRewardReport" element={<StakingRewardReport />} />
          <Route path="/StakingRewardSidbar" element={<StakingRewardSidbar />} />
          <Route path="/withdrawalsidbar" element={<WithdrawalSidbar />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
