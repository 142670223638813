import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Sidebar from '../Comman/Sidebar'
// import Staking from '../Report/Staking'
import { AdminContext } from '../../AdminContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
// import Team from './Team'
// import StakingIncome from './StakingIncome'
// import DirectReward from './DirectReward'
// import CommunityReward from './CommunityReward'
// import RoyaltyClubReward from './RoyaltyClubReward'
// import RankReward from './RankReward'
// import Withdrawal from './Withdrawal'
// import Ledger from './Ledger'
// import StakingReport from './StakingReport'
import TeamReport from './TeamReport'
import Copy from '../Comman/Copy'
import ActivityClubRewardReport from './ActivityClubRewardReport'
import LiquidityHistoryReport from './LiquidityHistoryReport'
import PurchaseHistoryReport from './PurchaseHistoryReport'
import RoyaltyRewarReport from './RoyaltyRewarReport'
import SalaryRewardReport from './SalaryRewardReport'
import SponserReward from './SponserReward'
// import StakingRewardReport from './StakingRewardReport'
import TradeFundMobilisationReport from './TradeFundMobilisationReport'
import StakingRewardReport from './StakingRewardReport'
// import RoiOfRoiReport from './RoiOfRoiReport'

export default function MemberReport() {
    const { address } = useParams();
    const { formatAddress, formatDateTime } = useContext(AdminContext);
    const [data, setdata] = useState([]);
    const [totReward, setTotReward] = useState(0);

    const getData = async () => {
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getbyid",
                key: process.env.REACT_APP_KEY,
                address,
            })
            .then((res) => {
                // console.log('user report:', res.data.data)
                if (res.data.data) {
                    var dap = res.data.data;
                    var fbln =
                        dap.level_inc + dap.cashback_inc + dap.direct_inc + dap.magic_b_inc + dap.earth_p_inc + dap.global_p_inc + dap.rank_inc + dap.global_inc + dap.global_vip_inc + dap.w_royalty_inc + dap.n_w_royalty_inc - (dap.t_withdrawal + dap.pen_withdrawal + dap.d_m_entry + dap.d_e_entry + dap.d_g_entry);
                    setTotReward(fbln);
                    console.log("res.data:",res.data)
                    setdata(res.data.data);
                }
                if (res.data.error) {
                    return "";
                }
            });
    };
    useEffect(() => {
        // console.log('Fetching data for address:', address);
        if (address) {
            getData(address);
        }
    }, [address]);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>MemberReport</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Order Delivery*/}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Address</h5>
                                                            </div>

                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                <a
                                                                    target="_blank"
                                                                    className="text-primary"
                                                                    href={`${process.env.REACT_APP_EXPLORER}/address/${address}`}
                                                                    rel="noreferrer"
                                                                >
                                                                    {formatAddress(address)}
                                                                </a>
                                                                {address ? <Copy data={address} /> : ""}
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">REF.Address</h5>
                                                            </div>

                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                <a
                                                                    target="_blank"
                                                                    className="text-primary"
                                                                    href={`${process.env.REACT_APP_EXPLORER}/address/${address}`}
                                                                    rel="noreferrer"
                                                                >
                                                                    {formatAddress(address)}
                                                                </a>
                                                                {address ? <Copy data={address} /> : ""}
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Sponsor Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.t_reward_mining ?? "0"}</h3>                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Pending Sponsor Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.level_inc_pending ?? "0"}</h3>                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Mobilization Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.tfm_inc ?? "0"}</h3>                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Salary Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.salary_inc ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Activity Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.activity_club_inc ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Royalty Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                $0
                                                                {/* {data ? data.restake : "0"} */}
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Staking Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.staking_inc ??" 0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Activity Club</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">
                                                                {data && data.club !== null ? data.club : "Not Available"}
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Royalty</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.royalty ?? "0"}</h3>                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h4 className="f-18 font-light">Salary Achievement $100</h4>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ?.createdAt_7 ?? "-"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h4 className="f-18 font-light">Salary Achievement $250</h4>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ?.createdAt_15 ?? "-"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h4 className="f-18 font-light">Salary Achievement $500</h4>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ?.createdAt_21 ?? "-"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Bot</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.t_staking ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Liquidity</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.t_staking_s ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ?.team ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Direct Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{data ?.d_team ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Next Club</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3"> -
                                                                {/* {data ? data.r_team : 0} */}
                                                            </h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Team Business</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">${data ?.team_business_staking ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Direct Team Buisnesse</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">${data ?.direct_team_business_staking ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Sales Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$0</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Other Sales Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$0</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Pending Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$0</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.t_reward_mining ?? "0"}</h3>                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Bot Count</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data?.t_bot ?? "0"}</h3>                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Required Storng</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.royalty_r_strong ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Required Other</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {data ?.royalty_r_other ?? "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <StakingReport address={address} /> */}
                            <PurchaseHistoryReport address={address} />
                            <LiquidityHistoryReport address={address} />
                            <StakingRewardReport address={address} />
                            <SponserReward address={address} />
                            <TradeFundMobilisationReport address={address} />
                            <SalaryRewardReport address={address} />
                            <ActivityClubRewardReport address={address} />
                            <RoyaltyRewarReport address={address} />
                            <TeamReport address={address} />
                            {/* <RoiOfRoiReport address={address} /> */}
                            {/* <StakingRewardReport address={address} /> */}
                            {/*<StakingIncome address={address} />
                            <DirectReward address={address} />
                            <CommunityReward address={address} />
                            <RoyaltyClubReward address={address} />
                            <RankReward address={address} />
                            <Withdrawal address={address} />
                            <Ledger address={address} /> */}
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>


        </>
    )
}
